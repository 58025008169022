<template>
  <div class="content md no-padding flex-box">
    <a-spin class="setting-dept" :spinning="loading">
      <div class="setting-dept-box">
        <a-tree
          :defaultExpandedKeys="['0']"
          :tree-data="list"
          @select="handleSelect"
          v-if="list && list.length > 0">
          <template v-slot:dept="{title}">
            <a-icon class="dept-icon" type="folder" />
            <open-data type="departmentName" :openid="title" />
          </template>
        </a-tree>
      </div>
    </a-spin>
    <div class="permission-info">
      <a-descriptions title="权限设置" :column="1" v-if="select">
        <a-descriptions-item label="部门名称">
          <open-data type="departmentName" :openid="select.id" />
        </a-descriptions-item>
        <a-descriptions-item label="部门层级">{{numberText[select.key.split("-").length - 2]}}级部门</a-descriptions-item>
        <a-descriptions-item label="部门看板权限" v-if="select.info">
          <select-book type="user" :value="ids" :closeable="false" multiple @change="handleChange"></select-book>
        </a-descriptions-item>
      </a-descriptions>
      <div class="permission-info-tip" v-else>点击选择部门进行设置</div>
    </div>
  </div>
</template>

<script>
    import {omit} from "../../common/js/tool";

    export default {
        name: "PermissionSettings",
        data() {
            return {
                list: [],
                key: "",
                numberText: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
                edit: false,
                ids: [],
                loading: false
            }
        },
        computed: {
            select() {
                let res = null;
                const key = this.key;
                if(key) {
                    const arr = key.split("-");
                    const list = this.list;
                    while (arr.length > 0) {
                        const index = arr.splice(0, 1)[0];
                        res = res ? res.children[index] : list[index];
                    }
                }
                return res;
            }
        },
        watch: {
            select(val) {
                this.ids = val.info ? val.info.wx_user_id.split(",") : [];
            }
        },
        created() {
            this.getDeptList();
        },
        methods: {
            handleChange(val) {
                const select = this.select;
                if(select.info) {
                    this.$axios({
                        url: `/admin/address-dept/${select.info.id}`,
                        method: 'PATCH',
                        data: {
                            wx_user_id: val.join(",")
                        }
                    }).then(res => {
                        select.info.wx_user_id = res.wx_user_id;
                    }).catch(() => {
                        this.ids = select.info ? select.info.wx_user_id.split(",") : [];
                    });
                }
            },
            getDeptList() {
                this.loading = true;
                this.$axios("/admin/common/get-dept-board").then(res => {
                    if(res.error == 0) {
                        this.list = this.dealDeptList(res.data);
                    } else {
                        this.$message.error(res.msg || "获取部门列表失败");
                    }
                }).finally(() => this.loading = false);
            },
            dealDeptList(list, parent) {
                return list.map((item, index) => {
                    const dept = omit(item, ['children']);
                    dept.disabled = !parent;
                    dept.key = parent ? `${parent}-${index}` : index + '';
                    dept.title = dept.id;
                    dept.scopedSlots = {
                        title: "dept"
                    };
                    if(item.children) {
                        dept.children = this.dealDeptList(item.children, dept.key);
                    }
                    return dept;
                });
            },
            handleSelect(keys) {
                this.key = keys[0];
            }
        }
    }
</script>

<style lang="less">
.setting-dept {
  width: 360px;
  .ant-spin-container {
    height: 100%;
  }
}
.setting-dept-box {
  padding: 12px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.permission-info {
  flex: 1;
  border-left: var(--border);
  min-width: 0;
}
.dept-icon {
  margin-right: 6px;
  font-size: 16px;
  color: @blue-6;
}
.permission-info {
  padding: 24px;
}
.permission-info-tip {
  margin-top: 48px;
  text-align: center;
  color: @text-color-secondary;
}
</style>
